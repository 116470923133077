.arrow:enabled svg {
  @apply text-gray-900;
}

.arrow:enabled:hover svg {
  @apply text-gray-800;
}

.icon[data-icon='previous'] {
  @apply transform rotate-180;
}

.icon[data-icon='first'] {
  @apply transform rotate-180;
}
