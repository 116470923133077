.arrow:enabled svg {
  @apply text-gray-900;
}

.arrow:enabled:hover svg {
  @apply text-gray-800;
}

.icon[data-icon='previous'] {
  @apply transform rotate-180;
}

.button {
  @apply relative font-bold autoType300 transition-all duration-500 text-gray-800;
}

.button:enabled:hover {
  @apply text-gray-800 underline;
}

.button[data-variant='current'] {
  @apply underline;
}

.button[data-variant='current'],
.button:disabled {
  @apply cursor-auto text-gray-800;
}

.button[data-variant='current']:hover {
  @apply cursor-auto text-gray-800;
}

/* Focus State */
.button:focus-visible::after {
  @apply block rounded-full inset-2 absolute border-green-800 border-2;
  content: '';
}
