.randomStaysTitle {
  @apply mb-4 text-2xl font-medium border-t mt-9 pt-9 border-neutral-20;
}

.randomStaysTitle + h2 {
  @apply hidden;
}

.randomStaysTitle + h2 + div div[class*='ListingTile_image'] {
  @apply min-w-0;
}

@screen md {
  .randomStaysTitle + h2 + div {
    @apply grid-cols-4;
  }
}
